<template lang="pug">
  .new-material-wrapper
    h1 {{ header }}

    form(@submit.prevent="saveMaterial")
      // .form-group
        .btn-group(role="group" aria-label="Basic example")
          button(type="button" class="btn btn-primary") Видео
          button(type="button" class="btn btn-secondary" :disabled="true") Фото
          button(type="button" class="btn btn-secondary" :disabled="true") Что-то еще

      .form-group
        input.form-control(type="text"
                           placeholder="Название видео"
                           v-model="material.title"
                           :class="{ 'is-invalid': $v.material.title.$invalid && $v.material.title.$error }")

        .invalid-feedback Обязательное поле

      .form-group(v-if="!material.url")
        input.form-control(type="file" @change="catchFile"
          accept="video/mp4,video/x-m4v,video/*"
          :class="{ 'is-invalid': $v.material.file.$invalid && $v.material.file.$error }")

        .invalid-feedback Обязательное поле

        .col-12.col-sm-6.col-md-6.col-lg-3(v-if="material.file_url")
          label.mt-2 Предпросмотр
          br
          video-viewer(:url="material.file_url" :width="240" :height="180")

      .form-group(v-if="!material.file && !material.file_url")
        input.form-control(type="text"
                           placeholder="Ссылка на видео (youtube)"
                           v-model="material.url"
                           :class="{ 'is-invalid': $v.material.url.$invalid && $v.material.url.$error }")

        .invalid-feedback Обязательное поле или должна быть указана ссылка на видео

      .d-flex.align-items-center
        button.btn.btn-outline-success(type="submit" :disabled="loading") Сохранить видео
        button.btn.btn-outline-danger.ml-2.mr-2(v-if="material.id" @click.prevent="destroy") Удалить видео

        pulse-loader.ml-2(:loading="loading")
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import videoViewer from '@/components/common/viewers/video_viewer'
import PulseLoader from 'vue-spinner/src/PulseLoader'

export default {
  data () {
    return {
      material: {
        title: '',
        url: '',
        file: undefined
      }
    }
  },
  components: { videoViewer, PulseLoader },
  mounted () {
    if (!this.allMaterials.length) {
      this.getMaterials().then(() => {
        if (this.editableMaterial) {
          this.material = Object.assign({}, this.editableMaterial)
        }
      })
    } else {
      if (this.editableMaterial) {
        this.material = Object.assign({}, this.editableMaterial)
      }
    }
  },
  methods: {
    ...mapActions({
      createMaterial: 'materialsStore/create',
      updateMaterial: 'materialsStore/update',
      getMaterials: 'materialsStore/index',
      destroyMaterial: 'materialsStore/destroy'
    }),
    destroy () {
      if (confirm('Удалить?')) {
        this.destroyMaterial({ material: this.material }).then(() => {
          this.$notify({
            title: 'Удаление материала',
            type: 'success',
            text: 'Материал успешно удален'
          })

          setTimeout(() => {
            this.$router.push({ name: 'materials_index' })
          }, 200)
        }).catch(() => {
          this.$notify({
            title: 'Удаление материала',
            type: 'error',
            text: 'Удаление материала не удалось'
          })

          setTimeout(() => {
            this.$router.push({ name: 'materials_index' })
          }, 200)
        })
      }
    },
    saveMaterial () {
      this.$v.$touch()

      if (this.$v.$invalid) { return }

      const params = {
        params: this.material
      }

      if (this.editableMaterial) {
        this.updateMaterial(params).then(() => {
          this.$notify({
            title: 'Изменение материала',
            type: 'success',
            text: 'Материал успешно изменен'
          })

          this.$router.push({ name: 'materials_index' })
        }).catch(() => {
          this.$notify({
            title: 'Изменение материала',
            type: 'error',
            text: 'Изменение материала не удалось'
          })

          this.$router.push({ name: 'materials_index' })
        })
      } else {
        this.createMaterial(params).then(() => {
          this.$notify({
            title: 'Создание материала',
            type: 'success',
            text: 'Материал успешно создан'
          })

          this.$router.push({ name: 'materials_index' })
        }).catch(() => {
          this.$notify({
            title: 'Создание материала',
            type: 'error',
            text: 'Создание материала не удалось'
          })

          this.$router.push({ name: 'materials_index' })
        })
      }
    },
    catchFile (e) {
      const file = e.target.files[0]

      this.material.file = file
    }
  },
  computed: {
    ...mapGetters({
      allMaterials: 'materialsStore/all',
      loading: 'loading'
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    editableMaterial () {
      if (this.$route.params.id) {
        return this.allMaterials.find(m => parseInt(m.id) === parseInt(this.$route.params.id))
      }
    },
    header () {
      if (this.editableMaterial) {
        return 'Редактирование материала'
      }

      return 'Добавление материала'
    }
  },
  validations () {
    return {
      material: {
        title: { required },
        url: this.material.file || this.material.file_url ? {} : { required, url },
        file: this.material.url || this.material.file_url ? {} : { required }
      }
    }
  }
}
</script>
