<template lang="pug">
  form(v-if="currentUser" @submit.prevent="doUpdate")
    .form-group(v-for="{name, type, localized_name, options} in editable.profile_structure")
      label(:for="name") {{ localized_name }}
      input.form-control(:type="type" v-if="type === 'text'" :id="name" v-model="editable.profile[name]")
      textarea.form-control(rows="5" cols="10" v-model="editable.profile[name]" v-if="type === 'textarea'")
      select.custom-select(:id="name" v-model="editable.profile[name]" v-if="type === 'select'")
        option(v-for="option in options" :value="option.name") {{ option.localized_name }}

    button.btn.btn-primary(type="submit") Сохранить
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      editable: {}
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'current_user'
    })
  },
  mounted () {
    if (!this.currentUser) { return }

    this.editable = { ...this.currentUser }
    this.editable.profile = { ...this.currentUser.profile }
  },
  watch: {
    currentUser () {
      this.editable = { ...this.currentUser }
      this.editable.profile = { ...this.currentUser.profile }
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'updateUser'
    }),
    doUpdate () {
      const params = {
        user: this.editable
      }

      this.updateUser(params).then(() => {
        this.$notify({
          title: 'Обновление анкеты',
          type: 'success',
          text: 'Анкета успешно обновлена'
        })
      })
    }
  }
}
</script>
