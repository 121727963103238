<template lang="pug">
  li.training-program-wrapper
    router-link(:to="{ name: 'pupil_training_program_show', params: { program_id: training_program.id } }") {{ training_program.title }}
</template>

<script>
export default {
  props: ['training_program']
}
</script>
