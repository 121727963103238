<template lang="pug">
  .modal.fade(id="newCategoryModal" ref="modal")
    .modal-dialog(role="document")
      .modal-content
        form.form(@submit.prevent="submit")
          .modal-header
            h5.modal-title(id="exampleModalLabel") Добавление новой категории
            button(type="button" class="close" data-dismiss="modal" aria-label="Close")
              span(aria-hidden="true") &times
          .modal-body
            .form-group
              label(for="#title")
              input.form-control(v-model="category.title" id="title" placeholder="Название категории")
          .modal-footer
            button(type="button" class="btn btn-secondary" data-dismiss="modal" id="close") Закрыть
            button(type="submit" class="btn btn-primary") Сохранить
</template>

<script>
import { mapActions } from 'vuex'

function defaultObject () {
  return {
    title: ''
  }
}

export default {
  data () {
    return {
      category: defaultObject()
    }
  },
  methods: {
    ...mapActions({
      create: 'categoriesStore/postCreate'
    }),
    submit () {
      if (!this.category.title) { return }

      this.create(this.category).then(() => {
        document.getElementById('close').click()

        this.category = Object.assign({}, defaultObject())
      })
    }
  }
}
</script>
