import Vue from '@/lib/vue'
import Vuex from 'vuex'
import common from './modules/common'
import coach from './modules/coach/coach'
import coachPupils from './modules/coach/pupils'
import sharedTrainingPrograms from './modules/coach/sharedTrainingProgram'
import materialsStore from './modules/coach/materials'
import categoriesStore from './modules/coach/categories'
import trainingProgramsStore from './modules/coach/trainingPrograms'
import nutritionProgramsStore from './modules/coach/nutritionPrograms'
import diaryStore from './modules/diary'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  modules: {
    common,
    coach,
    coachPupils,
    materialsStore,
    categoriesStore,
    sharedTrainingPrograms,
    trainingProgramsStore,
    nutritionProgramsStore,
    diaryStore
  },
  strict: process.env.NODE_ENV === 'development',
  plugins: debug ? [createLogger()] : []
})
