<template lang="pug">
  .tab-pane(:id="`ingestion-${sectionIndex}-${index}`" role="tabpanel"
                 :class="{ 'show': isActive, 'active': isActive }")

    a.new-item__add(href="#" @click.prevent="addNewIngestionItem") Добавить продукты

    .section.mt-3.mb-3(v-if="item.nutrition_program_ingestions_attributes")
      .section__item(v-for="(ingestion, i) in item.nutrition_program_ingestions_attributes"
        v-if="!ingestion._destroy")
        .form-inline.section__attributes
          .col-12.d-flex.justify-content-end
            a(href="#" @click.prevent="emitRemoveIngestion(i)") Удалить продукт
              font-awesome-icon.ml-2(icon="trash")

          label(:for="`title-${index}-${sectionIndex}-${i}`") Продукт
          input.section__item_title.form-control.col-12.mr-2.mb-2(v-model="ingestion.title"
            placeholder="Продукт"
            type="text"
            :id="`title-${index}-${sectionIndex}-${i}`")

          label(:for="`portion-${index}-${sectionIndex}-${i}`") Порция
          input.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="ingestion.portion"
            placeholder="Порция"
            type="text"
            :id="`portion-${index}-${sectionIndex}-${i}`")

          label(:for="`calorie_content-${index}-${sectionIndex}-${i}`") Ккал
          input.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="ingestion.calorie_content"
            placeholder="Ккал"
            type="text"
            :id="`calorie_content-${index}-${sectionIndex}-${i}`")

        hr

    a.new-item__add(href="#" @click.prevent="addNewIngestionItem") Добавить продукты

    hr
</template>

<script>
import { nutritionProgramMixin } from '@/mixins/nutritionProgramMixin'

export default {
  props: {
    item: {
      required: true,
      type: Object,
      default: () => {}
    },
    sectionIndex: {
      required: true,
      type: Number,
      default: 0
    },
    index: {
      required: true,
      type: Number,
      default: 0
    }
  },
  mixins: [nutritionProgramMixin],
  computed: {
    isActive () {
      return this.index === 0
    }
  },
  methods: {
    emitRemoveIngestion (index) {
      const ingestion = this.item.nutrition_program_ingestions_attributes[index]

      if (ingestion.id) {
        this.$set(this.item.nutrition_program_ingestions_attributes, index, {
          id: ingestion.id,
          _destroy: true
        })
      } else {
        this.item.nutrition_program_ingestions_attributes.splice(index, 1)
      }
    },
    addNewIngestionItem () {
      this.item.nutrition_program_ingestions_attributes.push(this.ingestionItem())
    }
  }
}
</script>
