<template lang="pug">
  .nutrition-program-show.mt-3
    .nutrition-program-show__title.mb-4 # {{ nutritionProgram.title }}
      small(v-if="canEdit")
        router-link(:to="{ name: 'edit_pupil_nutrition_program', params: { program_id: nutritionProgram.id }}")
          font-awesome-icon.ml-2(icon="edit")

    template(v-if="nutritionProgram.comment")
      .comment.mt-3
        h5 Комментарий тренера для программы питания
        blockquote.blockquote {{ nutritionProgram.comment }}

      hr

    b-tabs(pills)
      b-tab(:title="section.title" :title-item-class="['r-pill', 'mr-3']" :active="index === 0" :key="`section-${section.id}-${index}`" v-for="(section, index) in nutritionProgram.sections_attributes")
        b-tabs.mt-3(pills)
          b-tab(:title="item.title" :title-item-class="['r-pill', 'mr-3']" :active="itemIndex === 0" :key="`section-item-${item.id}-${itemIndex}`" v-for="(item, itemIndex) in section.nutrition_program_items_attributes")
            .nutrition-program-show-item.mt-3(v-for="ingestion in item.nutrition_program_ingestions_attributes"
                                        v-if="ingestion.title")
              .nutrition-program-show-item__title-wrapper
                .square
                .nutrition-program-show-item__title {{ ingestion.title }}

              .nutrition-program-show-item__items
                .nutrition-program-show-item__item(v-if="ingestion.portion")
                  img.img-fluid(src="@/assets/comment.png")
                  .nutrition-program-show-item__item-info
                    | Порция {{ ingestion.portion }}

                .nutrition-program-show-item__item(v-if="ingestion.calorie_content")
                  img.img-fluid(src="@/assets/comment.png")
                  .nutrition-program-show-item__item-info
                    | {{ ingestion.calorie_content }} ккал
</template>

<script>
export default {
  props: {
    nutritionProgram: {
      required: true,
      type: Object,
      default: () => {}
    },
    canEdit: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>
