import diaryAPI from '@/api/diaryBackend/diaries'

const diaryStore = {
  namespaced: true,
  state: {
    all: []
  },

  getters: {
    getDairyFor: (state) => (tpID, sectionID, itemID) => {
      return state.all.filter(d => d.TrainingProgramID === tpID && d.SectionID === sectionID && d.ItemID === itemID)
    }
  },

  actions: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ping ({ _commit }) {
      const response = await diaryAPI.ping()

      return response
    },
    async index ({ commit }, { userID, trainingProgramID }) {
      const response = await diaryAPI.index(userID, { trainingProgramID: trainingProgramID })

      commit('setAll', response.data)
    },
    async create ({ commit }, { userID, params }) {
      const response = await diaryAPI.create(userID, params)

      commit('push', response.data)
    },
    async update ({ commit }, { userID, diaryID, params }) {
      const response = await diaryAPI.update(userID, diaryID, params)

      commit('update', { diaryID: diaryID, diaryRecord: response.data })
    },
    async delete ({ commit }, { userID, diaryID }) {
      const response = await diaryAPI.delete(userID, diaryID)

      commit('delete', diaryID)

      return response
    }
  },

  mutations: {
    push (state, diaryRecord) {
      state.all.push(diaryRecord)
    },
    setAll (state, records) {
      state.all = records
    },
    delete (state, diaryID) {
      const idx = state.all.map(d => d.ID).indexOf(diaryID)

      state.all.splice(idx, 1)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update (state, { userID, diaryRecord }) {
      const idx = state.all.map(d => d.ID).indexOf(diaryRecord.ID)
      const item = state.all[idx]

      Object.assign(item, diaryRecord)
    }
  }
}

export default diaryStore
