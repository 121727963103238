<template lang="pug">
  .pupil-training-programs
    h4 Программы тренировок
      router-link.ml-2(:to="{ name: 'new_pupil_training_program', params: { id: currentPupil.id } }")
        font-awesome-icon(icon="plus")

    p(v-if="currentPupil.training_program")
      router-link(:to="{ name: 'pupil_training_program_show', params: { program_id: currentPupil.training_program.id } }") {{ currentPupil.training_program.title }}
      span.badge.badge-secondary.ml-1 Актуальная

    b-button(v-b-toggle.trainingProgramsList
             variant="outline-primary"
             v-if="currentPupil.training_programs.length") Весь список программ тренировок
    b-collapse(id="trainingProgramsList")
      ul.mt-2(id="traning-programs-list")
        training-program(v-for="program in currentPupil.training_programs"
          :key="program.id"
          :training_program="program")
</template>

<script>
import trainingProgram from './training_program'
import { mapGetters } from 'vuex'

export default {
  components: { trainingProgram },
  computed: {
    ...mapGetters({
      currentPupil: 'coachPupils/current'
    })
  }
}
</script>
