

















import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      exit: 'exit'
    })
  },
  computed: {
    ...mapGetters({
      isCoach: 'isCoach'
    })
  }
})

export default class Dashboard extends Vue {
  isCoach!: Boolean
  exit!: () => any

  doExit () {
    this.exit().then(() => {
      window.location.href = '/'
    }).catch(() => {
      window.location.href = '/'
    })
  }

  mounted () {
    if (this.$route.name === 'settings_dashboard') {
      this.$router.replace({ name: 'settings_main' })
    }
  }

  updated () {
    if (this.$route.name === 'settings_dashboard') {
      this.$router.replace({ name: 'settings_main' })
    }
  }
}

