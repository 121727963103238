import materialsApi from '@/api/mainBackend/coach/materials'

const materialsStore = {
  namespaced: true,
  state: {
    materials: [],
    page: 1
  },

  getters: {
    all: state => state.materials,
    findMaterialById: state => param => state.materials.find(m => m.id === param),
    page: state => state.page
  },

  actions: {
    async index ({ getters, commit }) {
      const response = await materialsApi.index({ page: getters.page })

      commit('setMaterials', response.data.items)
      commit('incrementPage')

      return response.data
    },
    async create ({ commit }, payload) {
      commit('incrementUploadsQuantity', null, { root: true })

      const response = await materialsApi.create(payload.params, payload.progress)
      commit('pushMaterial', response.data)
      commit('decrementUploadsQuantity', null, { root: true })

      return response.data
    },
    async update ({ commit }, payload) {
      commit('setLoading', true)

      const response = await materialsApi.update(payload.params)
      commit('setMaterial', response.data)

      commit('setLoading', false)
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    async destroy ({ commit }, payload) {
      await materialsApi.destroy(payload)
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },

  mutations: {
    setMaterials (state, materials) {
      materials.map((material) => {
        if (state.materials.map(p => p.id).indexOf(material.id) === -1) {
          state.materials.push(material)
        }
      })
    },
    pushMaterial (state, material) {
      const existingMaterial = state.materials.find(m => m.id === material.id)

      if (existingMaterial) {
        return
      }

      state.materials.unshift(material)
    },
    setMaterial (state, material) {
      // eslint-disable-next-line eqeqeq
      const item = state.materials.find(m => m.id == material.id)

      Object.assign(item, material)
    },
    destroyMaterial (state, material) {
      const index = state.materials.map(e => e.id).indexOf(material.material.id)

      state.materials.splice(index, 1)
    },
    incrementPage (state) {
      state.page++
    }
  }
}

export default materialsStore
