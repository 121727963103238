<template lang="pug">
  nutrition-program(:nutrition-program="actualNutritionProgram" v-if="actualNutritionProgram")
</template>

<script>
import nutritionProgram from '@/views/common/nutritionProgram/show'
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted () {
    if (!this.actualNutritionProgram) {
      this.getActualNutritionProgram({ user_id: this.currentUser.id })
    }
  },
  components: { nutritionProgram },
  computed: {
    ...mapGetters({
      currentUser: 'current_user',
      actualNutritionProgram: 'actualNutritionProgram'
    })
  },
  methods: {
    ...mapActions({
      getActualNutritionProgram: 'getActualNutritionProgram'
    })
  }
}
</script>
