import sharedTrainingProgramApi from '@/api/mainBackend/coach/sharedTrainingProgram'

const sharedTrainingProgramStore = {
  namespaced: true,
  state: {
    all: [],
    page: 1,
    current: {}
  },

  getters: {
    all: state => state.all,
    page: state => state.page,
    current: state => state.current
  },

  actions: {
    async getIndex ({ commit, getters }, payload) {
      const response = await sharedTrainingProgramApi.getTrainingPrograms({ page: getters.page, ...payload })

      commit('pushSharedTrainingPrograms', response.data)
      commit('incrementPage')

      return response.data
    },

    async postCreate ({ commit }, payload) {
      const params = { training_program: payload.shared_training_program }
      const response = await sharedTrainingProgramApi.createTrainingProgram(params)
      commit('unshiftItem', response.data.item)

      return response.data
    },

    async putUpdate ({ commit }, payload) {
      const params = { training_program: payload.shared_training_program }
      const response = await sharedTrainingProgramApi.updateTrainingProgram(params)
      commit('setItem', response.data.item)

      return response.data
    },

    async deleteDestroy ({ commit }, id) {
      await sharedTrainingProgramApi.deleteTrainingProgram(id)

      commit('deleteItem', id)
    },

    async getShow ({ commit }, payload) {
      const response = await sharedTrainingProgramApi.getTrainingProgram(payload)

      commit('setCurrent', response.data.item)

      return response
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async saveTrainingProgramPupils ({ commit }, payload) {
      return await sharedTrainingProgramApi.savePupilsList(payload)
    },
    nullifyPage ({ commit }) { commit('nullifyPage') },
    nullifyTrainingPrograms ({ commit }) { commit('nullifyTrainingPrograms') }
  },

  mutations: {
    pushSharedTrainingPrograms (state, data) {
      data.map((stp) => {
        if (state.all.map(p => p.id).indexOf(stp.id) === -1) {
          state.all.push(stp)
        }
      })
    },
    incrementPage (state) {
      state.page++
    },
    unshiftItem (state, item) {
      state.all.unshift(item)
    },
    setCurrent (state, item) {
      state.current = item
    },
    setItem (state, item) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let obj = state.all.find(o => o.id === item.id)

      obj = item
    },
    deleteItem (state, id) {
      const itemIdx = state.all.map(i => i.id).indexOf(id)

      if (itemIdx !== -1) {
        state.all.splice(itemIdx, 1)
      }
    },
    nullifyPage (state) {
      state.page = 1
    },
    nullifyTrainingPrograms (state) {
      state.all = []
    }
  }
}

export default sharedTrainingProgramStore
