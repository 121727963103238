<template lang="pug">
.training-program-show.mt-3
  .training-program-show__title # {{ trainingProgram.title }}
    small(v-if="canEdit")
      router-link(:to="editPath")
        font-awesome-icon.ml-2(icon="edit")

  b-tabs(pills)
    b-tab(:title="section.title"
          :title-item-class="['r-pill', 'mr-3', 'mt-3']"
          :active="index === 0"
          :key="`section-${section.id}-${index}`"
          v-for="(section, index) in trainingProgram.sections_attributes")

      .training-program-show__item-titles
        span.hash #
        span.titles {{ sectionsItemTitles(section) }}

      //.comment.mt-3(v-if="section.comment")
        //h5 Комментарий тренера для выбранного тренировочного дня
        //blockquote.blockquote {{ section.comment }}

      //hr

      .training-program-show-item(
        v-for="item in section.training_program_items_attributes"
      )
        .training-program-show-item__title-wrapper
          .square
          .training-program-show-item__title {{ item.title }}
            span.badge {{ badgeFor(item) }}

          div(
            v-for="tpim in item.training_program_item_materials_attributes"
            v-if="item.training_program_item_materials_attributes && item.training_program_item_materials_attributes.length > 0"
          )
            b-button.ml-2.font-size-12(v-b-modal="`modal-section-${item.id}-${tpim.id}`"
                                      v-if="tpim.material_id")
              b-icon(icon="play-fill")

            b-modal(:title="item.title"
                    :id="`modal-section-${item.id}-${tpim.id}`"
                    size="xl"
                    :ok-only="true" ok-title="Закрыть"
                    :centered="true"
                    @show="loadVideo($event, tpim.material_id, `modal-section-${item.id}-${tpim.id}`)"
                    button-size="sm")
              .embed-responsive.embed-responsive-16by9(
                v-html="item.content",
                v-if="item.content"
              )
              video-viewer(
                :url="videos[tpim.material_id].file_url",
                :poster="videos[tpim.material_id].preview",
                v-if="videoExists(tpim.material_id)"
              )

              pulse-loader(v-if="!videoExists(tpim.material_id)")

        .training-program-show-item__approaches
          | Подходы и повторения [{{ numberOfApproaches(item) }}]

        .training-program-show-item__items
          .training-program-show-item__item(v-if="item.weight")
            img.img-fluid(src="@/assets/weight.png")
            .training-program-show-item__item-info
              | Рабочий вес {{ item.weight }}

          .training-program-show-item__item(v-if="item.rest_time")
            img.img-fluid(src="@/assets/time.png")
            .training-program-show-item__item-info
              | Время отдыха {{ item.rest_time }}

        .training-program-show-item__diary.mt-3
          h5(v-if="diaryAvailable && diaryFor(section.id, item.id).length") Активность тренировки

          .table.table-responsive.table-hover(v-if="diaryAvailable && diaryFor(section.id, item.id).length")
            thead
              th №
              th Повторений
              th Вес, кг
              th
            tbody
              tr(v-for="diaryRecord in diaryFor(section.id, item.id)")
                td(@click="showEditDiaryForm(diaryRecord, `modal-section-diary-${item.id}`, section.id, item.id)") {{ diaryRecord.Approach }}
                td(@click="showEditDiaryForm(diaryRecord, `modal-section-diary-${item.id}`, section.id, item.id)") {{ diaryRecord.NumberOfRepeats }}
                td(@click="showEditDiaryForm(diaryRecord, `modal-section-diary-${item.id}`, section.id, item.id)") {{ diaryRecord.Weight }} кг
                td
                  a(href='#' @click.prevent="deleteDiaryRecord({ userID: currentPupil.id, diaryID: diaryRecord.ID })")
                    font-awesome-icon.ml-2(icon="trash")

          a(href='#' v-b-modal="`modal-section-diary-${item.id}`" v-if="diaryAvailable && isPupil") + Записать подход

        b-modal(:id="`modal-section-diary-${item.id}`"
                :title="diaryRecordModalTitle"
                :centered="true"
                ok-title="Сохранить"
                cancel-title="Отменить"
                @cancel="resetDiaryRecord"
                @ok="doDiaryAction($event, section.id, item.id)")
          .col-12
            form(:ref="`modal-section-diary-form-${item.id}`" @submit.prevent="diaryFormSubmit")
              .mb-3
                label(for="number_of_repeats") Кол-во повторений
                input.form-control(type="number" id="number_of_repeats" v-model="diaryRecord.NumberOfRepeats" required)

              .mb-3
                label(for="weight") Вес, кг
                input.form-control(type="number" id="weight" v-model="diaryRecord.Weight" required)

        .training-program-show-item__comment(v-if="item.comment")
          img(src="@/assets/comment.png")
          span {{ item.comment }}
</template>

<script>
import videoViewer from '@/components/common/viewers/video_viewer'
import TrainingProgramsTypeDetector from '@/bl/trainingProgramsTypeDetector'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { currentPupilMixin } from '@/mixins/currentPupilMixin'
import materialsApi from '@/api/mainBackend/coach/materials'

function defaultDiaryRecord () {
  return {
    Approach: 1,
    Weight: undefined,
    Duration: 0,
    NumberOfRepeats: undefined,
    TrainingProgramID: undefined,
    SectionID: undefined,
    ItemID: undefined,
    UserID: undefined
  }
}

export default {
  components: { videoViewer },
  data () {
    return {
      typeDetectorInstance: undefined,
      diaryRecord: defaultDiaryRecord(),
      diaryAvailable: false,
      diaryRecordModalTitle: 'Запись подхода',
      videos: {}
    }
  },
  props: {
    trainingProgram: {
      required: true,
      type: Object,
      default: () => {}
    },
    canEdit: {
      required: false,
      type: Boolean,
      default: false
    },
    formType: {
      required: false,
      type: String,
      default: () => 'training_program'
    }
  },
  mixins: [currentPupilMixin],
  mounted () {
    this.typeDetectorInstance = new TrainingProgramsTypeDetector(
      this.formType,
      this.$route
    )

    this.pingDiary().then(() => {
      this.diaryAvailable = true
      setTimeout(() => {
        this.diaryIndex({ userID: this.currentPupil.id, trainingProgramID: this.trainingProgram.id }).then(() => {

        }).catch(() => {
        })
      }, 300)
    })
  },
  computed: {
    ...mapGetters({
      getDiaryFor: 'diaryStore/getDairyFor',
      isPupil: 'isPupil'
    }),
    editPath () {
      if (this.formType === 'training_program') {
        return {
          name: 'edit_pupil_training_program',
          params: { program_id: this.trainingProgram.id }
        }
      } else if (this.formType === 'shared_training_program') {
        return {
          name: 'edit_shared_training_program',
          params: { id: this.trainingProgram.id }
        }
      }

      return null
    }
  },
  methods: {
    ...mapActions({
      createDiary: 'diaryStore/create',
      updateDiary: 'diaryStore/update',
      diaryIndex: 'diaryStore/index',
      pingDiary: 'diaryStore/ping',
      deleteDiaryRecord: 'diaryStore/delete'
    }),
    numberOfApproaches (item) {
      if (item.number_of_approaches && item.number_of_repeats) {
        return `${item.number_of_approaches}X${item.number_of_repeats}`
      } else if (item.number_of_repeats) {
        return item.number_of_repeats
      } else if (item.number_of_approaches) {
        return item.number_of_approaches
      } else {
        return 'Не указано'
      }
    },
    sectionsItemTitles (section) {
      return section.training_program_items_attributes
        .map((i) => i.title)
        .join('|')
    },
    doDiaryAction ($event, sectionID, itemID) {
      this.$v.$touch()

      if (this.$v.$invalid) {
        $event.preventDefault()
        return
      }

      if (this.diaryRecord.ID) {
        this.diaryRecord.Weight = parseInt(this.diaryRecord.Weight)
        this.diaryRecord.Duration = parseInt(this.diaryRecord.Duration)
        this.diaryRecord.NumberOfRepeats = parseInt(this.diaryRecord.NumberOfRepeats)

        this.updateDiary({ userID: this.currentPupil.id, diaryID: this.diaryRecord.ID, params: this.diaryRecord }).then(() => {
          this.resetDiaryRecord()
        })
      } else {
        this.diaryRecord.Approach = this.diaryFor(sectionID, itemID).length + 1
        this.diaryRecord.TrainingProgramID = this.trainingProgram.id
        this.diaryRecord.SectionID = sectionID
        this.diaryRecord.ItemID = itemID
        this.diaryRecord.Weight = parseInt(this.diaryRecord.Weight)
        this.diaryRecord.Duration = parseInt(this.diaryRecord.Duration)
        this.diaryRecord.NumberOfRepeats = parseInt(this.diaryRecord.NumberOfRepeats)

        this.createDiary({ userID: this.currentPupil.id, params: this.diaryRecord }).then(() => {
          this.resetDiaryRecord()
        }).catch(() => {
          $event.preventDefault()
        })
      }
    },
    diaryFormSubmit () {
    },
    diaryFor (sectionID, itemID) {
      return this.getDiaryFor(this.trainingProgram.id, sectionID, itemID)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showEditDiaryForm (diaryRecord, modalID, sectionID, itemID) {
      if (diaryRecord.ID) {
        this.diaryRecordModalTitle = `Редактирование подхода №${diaryRecord.Approach}`
      }

      Object.assign(this.diaryRecord, diaryRecord)

      this.$root.$emit('bv::show::modal', modalID)
    },
    resetDiaryRecord () {
      Object.assign(this.diaryRecord, defaultDiaryRecord())
      this.diaryRecordModalTitle = 'Запись подхода'
      delete this.diaryRecord.ID
    },
    loadVideo ($event, materialID, modalID) {
      if (!this.videoExists(materialID)) {
        $event.preventDefault()
        materialsApi.show(materialID).then(response => {
          this.videos[materialID] = response.data

          this.$forceUpdate()
          this.$root.$emit('bv::show::modal', modalID)
        })
      } else {
        this.$root.$emit('bv::show::modal', modalID)
      }
    },
    videoExists (materialID) {
      if (!this.videos[materialID]) return false
      if (!this.videos[materialID].file_url) return false

      return true
    },
    badgeFor (item) {
      switch (item.training_program_item_materials_attributes.length) {
        case 1:
          return ''
        case 2:
          return 'Суперсет'
        case 3:
          return 'Трисет'
      }
    }
  },
  validations: {
    diaryRecord: {
      Weight: { required },
      NumberOfRepeats: { required },
      Duration: { required }
    }
  }
}
</script>
