import inviteLinkAPI from '@/api/mainBackend/coach/inviteLink'

const coachStore = {
  namespaced: true,
  state: {},

  getters: {},

  actions: {
    async createInviteLink () {
      const response = await inviteLinkAPI.create()

      return response.data
    }
  },

  mutations: {}
}

export default coachStore
