<template lang="pug">
  .training-program-form
    training-program-form(form-type="shared_training_program")
</template>

<script>
import trainingProgramForm from '@/views/common/trainingProgram/form/form'

export default {
  components: { trainingProgramForm }
}
</script>
