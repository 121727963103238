<template lang="pug">
  .training-program-show-wrapper(v-if="currentPupil && trainingProgram")
    hr
    h2.mb-4.mt-2 Просмотр программы тренировки "{{ trainingProgram.title }}"
      small
        br
        router-link(:to="{ name: 'pupil_training_programs', params: { id: currentPupil.id } }") Назад к списку программ
    button.r-coach-btn.r-coach-btn__width-auto.mb-2(v-if="!trainingProgram.actual" @click="markAsActual") Сделать актуальной

    trainingProgram(:training-program="trainingProgram"
                    :can-edit="currentUser.role === 'coach'"
                    :form-type="formType"
                    v-if="trainingProgram")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import trainingProgram from '@/views/common/trainingProgram/show'

export default {
  data () {
    return {
      activeIndex: 0,
      formType: 'training_program'
    }
  },
  components: { trainingProgram },
  computed: {
    ...mapGetters({
      getPupilTrainingProgram: 'coachPupils/getPupilTrainingProgram',
      currentPupil: 'coachPupils/current',
      currentUser: 'current_user'
    }),
    trainingProgram () {
      return this.getPupilTrainingProgram({ pupil_id: this.currentPupil.id, program_id: this.$route.params.program_id })
    }
  },
  methods: {
    ...mapActions({
      updateActual: 'trainingProgramsStore/update'
    }),
    async markAsActual () {
      await this.updateActual({
        training_program: {
          id: this.trainingProgram.id,
          actual: true,
          pupil_id: this.currentPupil.id
        }
      })

      this.$notify({
        title: 'Обновление программы',
        type: 'success',
        text: 'Программа помечена, как актуальная'
      })
    }
  }
}
</script>
