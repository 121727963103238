<template lang="pug">
  .row(v-if="currentUser")
    .col
      router-view
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  mounted () {
    if (this.$route.name === 'pupil_index') {
      setTimeout(() => {
        this.$router.push({ name: 'pupil_training_program' })
      }, 300)
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'current_user'
    })
  }
}
</script>
