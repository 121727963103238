export const nutritionProgramMixin = {
  methods: {
    ingestionItem () {
      return {
        title: '',
        portion: '',
        calorie_content: ''
      }
    },
    nutritionProgramItem () {
      const position = this.section.nutrition_program_items_attributes.filter((i) => !i._destroy).length + 1

      return {
        title: `${position} прием`,
        nutrition_program_ingestions_attributes: [this.ingestionItem()]
      }
    },
    sectionItem () {
      const position = this.nutrition_program.sections_attributes.length + 1

      return {
        position: position,
        title: `День ${position}`,
        nutrition_program_items_attributes: [{
          title: '1 прием',
          nutrition_program_ingestions_attributes: [this.ingestionItem()]
        }]
      }
    }
  }
}
