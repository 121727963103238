<template lang="pug">
  .col.col-sm-2.col-md-2.mb-5.col-lg-2
    .card
      .card-body
        h5.card-title
          router-link(:to="{ name: 'category_show', params: { id: category.id }}") {{ category.title }}
      .card-footer
        | Видео в папке: {{ category.materials_count }}
</template>

<script>
export default {
  props: {
    category: {
      required: true,
      type: Object,
      default: () => {}
    }
  }
}
</script>
