<template lang="pug">
  .new-training-program-wrapper(v-if="currentPupil && training_program && training_program.sections_attributes")
    h1 {{ header }}

    form(@submit.prevent="saveTrainingProgram")
      .form-group
        input.form-control(type="text" v-model="training_program.title" placeholder="Название программы")

      ul.nav.nav-pills
        li.nav-item(@click.prevent="addSection")
          a.active.nav-link.mr-5.mt-2(href="#")
            font-awesome-icon.mr-2(icon="plus")
            span Добавить день

        li.nav-item(v-for="(section, index) in training_program.sections_attributes")
          a.nav-link.mt-2(href="#" :class="{ 'active': index === activeIndex }" @click.prevent="setActive(index)" v-if="!section._destroy") {{ section.title }}
            font-awesome-icon.ml-1(icon="trash" @click="removeSection(index)")

      hr

      .new-item(v-if="visibleSections.length")
        a.new-item__add(href="#" @click.prevent="addNewItem") Добавить упражнение

      form-section(v-for="(section, index) in training_program.sections_attributes"
                  :section="section"
                  :key="index"
                  :sectionIndex="index"
                  :is-active="activeIndex === index"
                  @setActive="setActive"
                  @removeTrainingProgramItem="removeTrainingProgramItem")

      .new-item(v-if="visibleSections.length")
        a.new-item__add(href="#" @click.prevent="addNewItem") Добавить упражнение

      hr

      .d-flex.align-items-center
        button.btn.btn-outline-success(type="submit" v-if="visibleSections.length" :disabled="uploadsQuantity > 0") Сохранить
        pulse-loader.ml-2(:loading="uploadsQuantity > 0")
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import cloneDeep from 'clone-deep'
import { mapGetters } from 'vuex'
import formSection from './section'
import TrainingProgramsTypeDetector from '@/bl/trainingProgramsTypeDetector'

export default {
  props: {
    formType: {
      required: false,
      type: String,
      default: () => 'training_program'
    }
  },
  components: { formSection },
  data () {
    return {
      training_program: {},
      activeIndex: 0,
      typeDetectorInstance: undefined
    }
  },
  mounted () {
    this.typeDetectorInstance = new TrainingProgramsTypeDetector(this.formType, this.$route)
    this.typeDetectorInstance.calcEntity()

    this.training_program = this.typeDetectorInstance.entity.trainingProgramEntity

    if (this.editable) {
      this.training_program = cloneDeep(this.editable)

      if (this.typeDetectorInstance.entityName === 'training_program') {
        this.training_program.pupil_id = this.$route.params.id
      }
    }
  },
  computed: {
    ...mapGetters({
      getPupilTrainingProgram: 'coachPupils/getPupilTrainingProgram',
      currentSharedTrainingProgram: 'sharedTrainingPrograms/current',
      uploadsQuantity: 'uploadsQuantity',
      currentPupil: 'coachPupils/current',
      currentUser: 'current_user'
    }),
    editable () {
      if (this.$route.name !== this.typeDetectorInstance.entity.editPath) { return }

      if (this.typeDetectorInstance.entityName === 'training_program') {
        return this.getPupilTrainingProgram({
          pupil_id: this.$route.params.id,
          program_id: this.$route.params.program_id
        })
      } else if (this.typeDetectorInstance.entityName === 'shared_training_program') {
        return this.currentSharedTrainingProgram
      }

      return {}
    },
    params () {
      const obj = {}
      obj[this.typeDetectorInstance.entityName] = this.training_program

      return obj
    },
    header () {
      if (this.editable) return 'Редактирование программы тренировок'

      return 'Добавление программы тренировок'
    },
    visibleSections () {
      return this.training_program.sections_attributes.filter(s => !s._destroy)
    }
  },
  methods: {
    create (payload) {
      return this.$store.dispatch(this.typeDetectorInstance.mappedCreateAction(), payload)
    },
    update (payload) {
      return this.$store.dispatch(this.typeDetectorInstance.mappedUpdateAction(), payload)
    },
    addSection () {
      const position = this.training_program.sections_attributes.length + 1

      this.training_program.sections_attributes.push({
        position: position,
        title: `День ${position}`,
        training_program_items_attributes: [this.trainingProgramItem()]
      })

      this.activeIndex = position - 1
    },
    trainingProgramItem () {
      return {
        title: '',
        number_of_repeats: '',
        number_of_approaches: '',
        weight: '',
        rest_time: '',
        training_program_item_materials_attributes: [{ position: 0, material_id: '' }],
        position: 1,
        comment: ''
      }
    },
    addNewItem () {
      const length = this.training_program.sections_attributes[this.activeIndex]
        .training_program_items_attributes.length

      this.training_program.sections_attributes[this.activeIndex]
        .training_program_items_attributes
        .push({ ...this.trainingProgramItem(), position: length + 1 })
    },
    setActive (index) {
      this.activeIndex = index
    },
    removeSection (index) {
      if (this.editable) {
        const section = this.training_program.sections_attributes[index]

        this.$set(this.training_program.sections_attributes, index, {
          id: section.id,
          _destroy: true
        })
      } else {
        this.training_program.sections_attributes.splice(index, 1)
      }

      if (index <= this.activeIndex) {
        this.activeIndex -= 1
      }
    },
    removeTrainingProgramItem ({ sectionIndex, index }) {
      if (this.editable) {
        const item = this.training_program.sections_attributes[sectionIndex].training_program_items_attributes[index]

        this.$set(this.training_program.sections_attributes[sectionIndex].training_program_items_attributes, index, {
          id: item.id,
          _destroy: true
        })
      } else {
        this.training_program.sections_attributes[sectionIndex].training_program_items_attributes.splice(index, 1)
      }
    },
    saveTrainingProgram () {
      this.$v.$touch()

      if (this.$v.$invalid) { return }

      if (this.editable) {
        this.doUpdate()
      } else {
        this.doCreate()
      }
    },
    doUpdate () {
      this.update(this.params).then((response) => {
        this.$notify({
          title: 'Обновление программы',
          type: 'success',
          text: 'Программа обновлена успешно'
        })

        this.redirectToShow(response.item.id)
      }).catch(() => {
        this.$notify({
          title: 'Обновление программы',
          type: 'error',
          text: 'Не удалось обновить программу'
        })
      })
    },
    doCreate () {
      this.create(this.params).then((response) => {
        this.$notify({
          title: 'Создание программы',
          type: 'success',
          text: 'Программа успешно создана'
        })

        this.redirectToShow(response.item.id)
      }).catch(() => {
        this.$notify({
          title: 'Создание программы',
          type: 'error',
          text: 'Не удалось создать программу'
        })
      })
    },
    redirectToShow (id) {
      let redirectId

      if (id) {
        redirectId = id
      } else {
        redirectId = this.item.id
      }

      this.$router.push(this.typeDetectorInstance.entity.returnPath(redirectId))
    }
  },
  validations: {
    training_program: {
      sections_attributes: { required },
      title: { required }
    }
  }
}
</script>
