





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import videoViewer from '@/components/common/viewers/video_viewer.vue'
import { IMaterial } from '@/interfaces/IMaterial'

@Component({
  components: { videoViewer },
  methods: {
    ...mapActions({
      updateMaterial: 'updateMaterial',
      setCategoryMaterial: 'categoriesStore/setCategoryMaterial'
    })
  },
  computed: {
    ...mapGetters({
      categories: 'categoriesStore/all'
    })
  }
})

export default class Material extends Vue {
  @Prop({}) item!: IMaterial

  updateMaterial!: (params: Object) => any
  setCategoryMaterial!: (params: Object) => any

  async update (event: any): Promise<void> {
    const id = parseInt(event.target.value)
    await this.updateMaterial({ material: { ...this.item, category_id: id, move: true } })

    this.setCategoryMaterial({ category_id: id })
  }
}
