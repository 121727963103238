import user from '@/api/mainBackend/user'
import trainingProgramApi from '@/api/mainBackend/coach/trainingProgram'
import nutritionProgramApi from '@/api/mainBackend/coach/nutritionProgram'

const state = {
  current_user: undefined,
  loading: false,
  uploadsQuantity: 0,
  actualTrainingProgram: undefined,
  trainingProgramsArchive: [],
  actualNutritionProgram: undefined,
  nutritionProgramsArchive: []
}

const getters = {
  current_user: state => state.current_user,
  isCoach: (state) => {
    if (!state.current_user) { return false }

    return state.current_user.role === 'coach'
  },
  isPupil: (state) => {
    if (!state.current_user) { return false }

    return state.current_user.role === 'pupil'
  },
  loading: state => state.loading,
  uploadsQuantity: state => state.uploadsQuantity,
  actualTrainingProgram: state => state.actualTrainingProgram,
  trainingProgramsArchive: state => state.trainingProgramsArchive,
  actualNutritionProgram: state => state.actualNutritionProgram,
  nutritionProgramsArchive: state => state.nutritionProgramsArchive
}

const actions = {
  async getUser ({ commit }) {
    const me = localStorage.getItem('me')

    if (me) {
      commit('setUser', JSON.parse(me))
      return
    }

    const response = await user.getUser()

    localStorage.setItem('me', JSON.stringify(response.data))

    commit('setUser', response.data)
  },
  async login ({ commit }, payload) {
    const response = await user.signIn(payload)

    commit('setToken', response.data.token)

    return response.data
  },
  async register ({ commit }, payload) {
    const response = await user.register(payload)

    console.log(response.data)

    commit('setToken', response.data.token)

    return response.data
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async sendResetPasswordInstructions ({ _commit }, payload) {
    const response = await user.sendResetPasswordInstructions(payload)

    return response.data
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async resetPassword ({ _commit }, payload) {
    const response = await user.resetPassword(payload)

    return response.data
  },
  async acceptInvitation ({ commit }, payload) {
    const response = await user.acceptInvitation(payload)

    commit('setToken', response.data.token)

    return response.data
  },
  async exit () {
    localStorage.removeItem('token')
    localStorage.removeItem('me')
  },
  async updateUser ({ commit }, payload) {
    const response = await user.update(payload)

    commit('setUser', response.data)
  },
  async uploadAvatar ({ commit }, payload) {
    const response = await user.uploadAvatar(payload)

    commit('setUser', response.data)

    return response.data
  },
  setLoading ({ commit }, payload) {
    commit('setLoading', payload)
  },
  async getActualTrainingProgram ({ commit, getters }) {
    const response = await trainingProgramApi.getActual({ user_id: getters.current_user.id })

    commit('setActualTrainingProgram', response.data)
  },
  async getActualNutritionProgram ({ commit, getters }) {
    const response = await nutritionProgramApi.getActual({ user_id: getters.current_user.id })

    commit('setActualNutritionProgram', response.data)
  },
  async getArchivedTrainingPrograms ({ commit, getters }) {
    const response = await trainingProgramApi.index({ user_id: getters.current_user.id })

    commit('setArchivedTrainingPrograms', response.data)
  }
}

const mutations = {
  setUser (state, user) {
    state.current_user = user
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setToken (state, payload) {
    state.token = payload

    localStorage.setItem('token', payload)
  },
  incrementUploadsQuantity (state) {
    state.uploadsQuantity += 1
  },
  decrementUploadsQuantity (state) {
    state.uploadsQuantity -= 1
  },
  setActualTrainingProgram (state, trainingProgram) {
    state.actualTrainingProgram = trainingProgram
  },
  setActualNutritionProgram (state, nutritionProgram) {
    state.actualNutritionProgram = nutritionProgram
  },
  setArchivedTrainingPrograms (state, items) {
    state.trainingProgramsArchive = items
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
