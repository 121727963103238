<template lang="pug">
  .training-program-show-wrapper(v-if="currentUser && trainingProgram")
    trainingProgram(:training-program="trainingProgram"
                    :can-edit="currentUser.role === 'coach'"
                    :form-type="formType"
                    v-if="trainingProgram")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import trainingProgram from '@/views/common/trainingProgram/show'

export default {
  data () {
    return {
      activeIndex: 0,
      trainingProgram: undefined,
      formType: 'training_program'
    }
  },
  mounted () {
    this.getTrainingProgram({ id: this.$route.params.id }).then(() => {
      this.trainingProgram = this.currentTrainingProgram
      this.formType = 'shared_training_program'
    })
  },
  components: { trainingProgram },
  computed: {
    ...mapGetters({
      currentTrainingProgram: 'sharedTrainingPrograms/current',
      currentUser: 'current_user'
    })
  },
  methods: {
    ...mapActions({
      getTrainingProgram: 'sharedTrainingPrograms/getShow'
    })
  }
}
</script>
