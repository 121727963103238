import { mapActions, mapGetters } from 'vuex'

export const currentPupilMixin = {
  mounted () {
    if (!this.currentPupil || parseInt(this.$route.params.id) !== this.currentPupil.id) {
      this.loadCurrentPupil()
    }
  },
  methods: {
    ...mapActions({
      getPupil: 'coachPupils/show',
      getTrainingPrograms: 'trainingProgramsStore/index',
      getActualTrainingProgram: 'trainingProgramsStore/actual',
      getNutritionPrograms: 'nutritionProgramsStore/index',
      getActualNutritionProgram: 'nutritionProgramsStore/getActual'
    }),
    loadCurrentPupil () {
      if (!this.id) { return }

      this.getPupil({ id: this.id }).then(() => {
        if (this.currentPupil.has_training_program && !this.currentPupil.training_program) {
          this.getActualTrainingProgram({ user_id: parseInt(this.id) })
        }

        if (this.currentPupil.has_training_programs && !this.currentPupil.training_programs.length) {
          this.getTrainingPrograms({ user_id: parseInt(this.id) })
        }

        if (this.currentPupil.has_nutrition_program && !this.currentPupil.nutrition_program) {
          this.getActualNutritionProgram({ user_id: parseInt(this.id) })
        }

        if (this.currentPupil.has_nutrition_programs && !this.currentPupil.nutrition_programs.length) {
          this.getNutritionPrograms({ user_id: parseInt(this.id) })
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      currentPupil: 'coachPupils/current',
      allPupil: 'coachPupils/all',
      currentUser: 'current_user'
    }),
    id () {
      if (this.$route.params.id) return this.$route.params.id
      if (this.currentUser) return this.currentUser.id
    }
  },
  watch: {
    currentUser: function () {
      this.loadCurrentPupil()
    }
  }
}
