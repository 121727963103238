<template lang="pug">
  .new-nutrition-program-wrapper(v-if="currentPupil && nutrition_program && nutrition_program.sections_attributes")
    h1 {{ header }}

    form(@submit.prevent="saveTrainingProgram")
      .form-group
        input.form-control(type="text" v-model="nutrition_program.title" placeholder="Название программы")

      // ul.nav.nav-pills(role="tablist" id="pills-tab")

      b-tabs(pills)
        template(#tabs-start)
          li.nav-item(@click.prevent="addSection")
            a.nav-link.mr-5.mt-2(href="#")
              font-awesome-icon.mr-2(icon="plus")
              span Добавить день
        b-tab(:title="section.title" :title-item-class="['r-pill', 'mr-3']" :active="index === 0" :key="`section-${section.position}-${index}`" v-if="!section._destroy" v-for="(section, index) in nutrition_program.sections_attributes")
          a(slot="title") {{ section.title }}
            font-awesome-icon.ml-1(icon="trash" @click="removeSection(index)")

          hr

          nutrition(:section="section"
                    :index="index"
                    :key="index")

          hr

      .form-group
        label(for="comment") Комментарий
        textarea.form-control(rows="3"
          cols="15"
          v-model="nutrition_program.comment"
          id="comment"
          placeholder="Комментарий")

      hr

      button.btn.btn-outline-success(type="submit" v-if="visibleSections.length") Сохранить
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import ingestion from './ingestion'
import { currentPupilMixin } from '@/mixins/currentPupilMixin'
import cloneDeep from 'clone-deep'
import nutrition from './nutrition'
import { nutritionProgramMixin } from '@/mixins/nutritionProgramMixin'

export default {
  components: { ingestion, nutrition },
  data () {
    return {
      nutrition_program: {
        pupil_id: this.$route.params.id,
        title: '',
        sections_attributes: [],
        comment: '',
        actual: true
      }
    }
  },
  mixins: [currentPupilMixin, nutritionProgramMixin],
  mounted () {
    // sections - дни
    // nutrtion_program_items - приемы
    // nutrition_program_ingestions - продукты

    if (this.editable) {
      this.nutrition_program = cloneDeep(this.editable)

      this.nutrition_program.pupil_id = this.$route.params.id
    }
  },
  computed: {
    ...mapGetters({
      getPupilNutritionProgram: 'coachPupils/getPupilNutritionProgram'
    }),
    editable () {
      if (this.$route.name !== 'edit_pupil_nutrition_program') { return }

      return this.getPupilNutritionProgram({
        pupil_id: this.$route.params.id,
        program_id: this.$route.params.program_id
      })
    },
    params () {
      return { nutrition_program: this.nutrition_program }
    },
    header () {
      if (this.editable) return 'Редактирование программы питания'

      return 'Добавление программы питания'
    },
    visibleSections () {
      return this.nutrition_program.sections_attributes.filter(s => !s._destroy)
    }
  },
  methods: {
    ...mapActions({
      create: 'nutritionProgramsStore/create',
      update: 'nutritionProgramsStore/update'
    }),
    addSection () {
      this.nutrition_program.sections_attributes.push(this.sectionItem())
    },
    removeSection (index) {
      const item = this.nutrition_program.sections_attributes[index]

      if (item.id) {
        this.$set(this.nutrition_program.sections_attributes, index, {
          id: item.id,
          _destroy: true
        })
      } else {
        this.nutrition_program.sections_attributes.splice(index, 1)
      }
    },
    saveTrainingProgram () {
      this.$v.$touch()

      if (this.$v.$invalid) { return }

      if (this.editable) {
        this.doUpdate()
      } else {
        this.doCreate()
      }
    },
    doUpdate () {
      this.update(this.params).then(() => {
        this.$notify({
          title: 'Обновление программы',
          type: 'success',
          text: 'Программа обновлена успешно'
        })

        this.redirectToShow()
      }).catch(() => {
        this.$notify({
          title: 'Обновление программы',
          type: 'error',
          text: 'Не удалось обновить программу'
        })
      })
    },
    doCreate () {
      this.create(this.params).then((response) => {
        this.$notify({
          title: 'Создание программы',
          type: 'success',
          text: 'Программа успешно создана'
        })

        this.redirectToShow(response.nutrition_program.id)
      }).catch(() => {
        this.$notify({
          title: 'Создание программы',
          type: 'error',
          text: 'Не удалось создать программу'
        })
      })
    },
    redirectToShow (id) {
      let redirectId
      if (id) {
        redirectId = id
      } else {
        redirectId = this.nutrition_program.id
      }

      this.$router.push({
        name: 'pupil_nutrition_program_show',
        params: {
          id: this.$route.params.id,
          program_id: redirectId
        }
      })
    }
  },
  validations: {
    nutrition_program: {
      pupil_id: { required },
      sections_attributes: { required },
      title: { required }
    }
  }
}
</script>
