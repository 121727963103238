import pupilsApi from '@/api/mainBackend/coach/pupils'
import usersApi from '@/api/mainBackend/user'

const pupilStore = {
  namespaced: true,
  state: {
    all: [],
    page: 1,
    current: {
      training_program: undefined,
      training_programs: [],
      nutrition_programs: [],
      nutrition_program: undefined
    },
    trainingProgram: {
      sections: []
    },
    nutritionProgram: {},
    shortList: []
  },

  getters: {
    all: state => state.all,
    current: state => state.current,
    getPupilTrainingProgram: (_state, getters) => (params) => {
      const pupil = getters.current

      if (!pupil.training_program) { return }

      if (pupil.training_program.id === parseInt(params.program_id)) { return pupil.training_program }

      const foundProgram = pupil.training_programs.find(e => e.id === parseInt(params.program_id))
      if (foundProgram) { return foundProgram }
    },
    getPupilNutritionProgram: (state, getters) => (params) => {
      const pupil = getters.current

      if (pupil.nutrition_program.id === parseInt(params.program_id)) { return pupil.nutrition_program }

      const foundProgram = pupil.nutrition_programs.find(e => e.id === parseInt(params.program_id))
      if (foundProgram) { return foundProgram }
    },
    page: state => state.page,
    shortList: state => state.shortList
  },

  actions: {
    async index ({ getters, commit }, payload) {
      const params = {
        page: getters.page,
        q: payload.q
      }
      const response = await pupilsApi.index(params)

      commit('setPupils', response.data.items)
      commit('incrementPage')

      return response.data
    },
    async show ({ commit, state }, payload) {
      let commitParams = {}

      if (state.all.map(p => p.id).includes(payload.id)) {
        commitParams = {
          pupilId: payload.id
        }
      } else {
        const response = await pupilsApi.show(payload)

        commitParams = {
          pupil: response.data
        }
      }

      commit('setCurrent', commitParams)
    },
    async create ({ commit }, payload) {
      const response = await pupilsApi.create(payload)

      commit('pushPupil', response.data)
    },
    async update ({ commit }, payload) {
      const response = await usersApi.update(payload)

      commit('updatePupil', response.data)

      return response.data
    },
    nullifyPage ({ commit }) { commit('nullifyPage') },
    nullifyPupils ({ commit }) { commit('nullifyPupils') },
    async getShortList ({ commit }) {
      const response = await pupilsApi.shortList()

      commit('setShortList', response.data.items)
    },

    async generateResetLink ({ commit }, pupilID) {
      const response = await pupilsApi.generateResetLink(pupilID)

      commit('setResetLink',
        {
          link: response.data,
          pupilID: pupilID
        }
      )
    }
  },

  mutations: {
    setPupils (state, pupils) {
      pupils.map((pupil) => {
        if (state.all.map(p => p.id).indexOf(pupil.id) === -1) {
          state.all.push(pupil)
        }
      })
    },
    setCurrent (state, { pupil, pupilId }) {
      const item = state.all.find(p => p.id === pupilId)

      if (item) {
        state.current = item
        return
      }

      state.current = pupil
    },
    pushPupil (state, pupil) {
      state.all.push(pupil)
    },
    updatePupil (state, pupil) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let item = state.all.find(p => p.id === pupil.id)
      item = { ...item, is_archived: pupil.is_archived }
    },
    incrementPage (state) {
      state.page++
    },
    setShortList (state, list) {
      state.shortList = list
    },
    nullifyPage (state) {
      state.page = 1
    },
    nullifyPupils (state) {
      state.all = []
    },
    setResetLink (state, { link, pupilID }) {
      const pupil = state.all.find(p => p.id === parseInt(pupilID))

      pupil.reset_link = link
    }
  }
}

export default pupilStore
