<template lang="pug">
  .list-group(v-if="currentUser")
    template(v-if="trainingProgramsArchive.length")
      router-link.list-group-item.list-group-item-action.flex-column.align-items-start(:to="{ name: 'training_programs_show', params: { program_id: program.id } }" v-for="program in trainingProgramsArchive" :key="program.id")
        .d-flex.w-100.justify-content-between
          h5.mb-1 {{ program.title }}
    template(v-else)
      p Пока нет архивных программ тренировок
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted () {
    if (!this.trainingProgramsArchive.length) {
      this.getArchivedTrainingPrograms()
    }
  },
  methods: {
    ...mapActions({
      getArchivedTrainingPrograms: 'getArchivedTrainingPrograms'
    })
  },
  computed: {
    ...mapGetters({
      currentUser: 'current_user',
      trainingProgramsArchive: 'trainingProgramsArchive'
    })
  }
}
</script>
