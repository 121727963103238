<template lang="pug">
  li.training-program-wrapper
    router-link(:to="{ name: 'pupil_nutrition_program_show', params: { program_id: program.id } }") {{ program.title }}
</template>

<script>
export default {
  props: {
    program: {
      required: true,
      type: Object,
      default: () => {}
    }
  }
}
</script>
