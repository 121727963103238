import trainingProgramApi from '@/api/mainBackend/coach/trainingProgram'

const trainingProgramsStore = {
  namespaced: true,
  state: {},
  getters: {},

  actions: {
    async index ({ commit, rootState }, payload) {
      const response = await trainingProgramApi.index(payload)
      const pupilsState = rootState.coachPupils

      commit('setIndex', { trainingPrograms: response.data, userId: payload.user_id, pupilsState: pupilsState })
    },
    async actual ({ commit, rootState }, payload) {
      const response = await trainingProgramApi.getActual(payload)
      const pupilsState = rootState.coachPupils

      commit('setActual', { trainingProgram: response.data, userId: payload.user_id, pupilsState: pupilsState })
    },
    async create ({ commit, rootState }, payload) {
      commit('setLoading', true, { root: true })
      const response = await trainingProgramApi.create(payload)
      const pupilsState = rootState.coachPupils

      commit('unshiftItem', { item: response.data.item, pupilsState: pupilsState })
      commit('setLoading', false, { root: true })

      return response.data
    },
    async update ({ commit, rootState }, payload) {
      commit('setLoading', true, { root: true })
      const response = await trainingProgramApi.update(payload)
      const pupilsState = rootState.coachPupils

      commit('setItem', { item: response.data.item, pupilsState: pupilsState })
      commit('setLoading', false, { root: true })

      return response.data
    }
  },

  mutations: {
    unshiftItem (state, { item, pupilsState }) {
      const pupil = pupilsState.current

      if (pupil === undefined) { return }

      pupil.training_programs.unshift(pupil.training_program)
      pupil.training_program = { ...item }
    },
    setItem (state, { item, pupilsState }) {
      if (pupilsState.current.training_program.id === item.id) {
        pupilsState.current.training_program = item
        return
      }

      if (item.actual) {
        const tp = pupilsState.current.training_program
        tp.actual = false
        const ids = pupilsState.current.training_programs.map(tp => tp.id)
        const index = ids.indexOf(tp.id)

        if (index === -1) {
          pupilsState.current.training_programs.push(Object.assign({}, tp))
        }

        if (ids.indexOf(item.id) !== -1) {
          pupilsState.current.training_programs.splice(ids.indexOf(item.id), 1)
        }

        pupilsState.current.training_program = item
      } else {
        const index = pupilsState.current.training_programs.map(tp => tp.id).indexOf(item.id)
        pupilsState.current.training_programs.splice(index, 1, item)
      }
    },
    setIndex (state, { trainingPrograms, userId, pupilsState }) {
      let user = pupilsState.all.find(u => u.id === userId)

      if (!user) { user = pupilsState.current }
      if (user.training_programs.length) { return }

      user.training_programs = trainingPrograms
    },
    setActual (state, { trainingProgram, userId, pupilsState }) {
      let user = pupilsState.all.find(u => u.id === userId)

      if (!user) { user = pupilsState.current }
      if (user.training_program) { return }

      user.training_program = trainingProgram
    }
  }
}

export default trainingProgramsStore
