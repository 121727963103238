<template lang="pug">
  b-tabs(pills)
    template(#tabs-start)
      li.nav-item(@click.prevent="addNewItem")
        a.nav-link.mr-5.mt-2(href="#")
          font-awesome-icon.mr-2(icon="plus")
          span Добавить прием
    b-tab(:title="section.title" :title-item-class="['r-pill', 'mr-3']" :active="i === 0" :key="`item-${item.position}-${i}`" v-if="!item._destroy" v-for="(item, i) in section.nutrition_program_items_attributes")
      a(slot="title") {{ item.title }}
        font-awesome-icon.ml-1(icon="trash" @click.prevent="removeNutritionProgramItem(i)")

      ingestion(:item="item"
                :sectionIndex="index"
                :index="i"
                :key="i")

      hr
</template>

<script>
import ingestion from './ingestion'
import { nutritionProgramMixin } from '@/mixins/nutritionProgramMixin'

export default {
  props: {
    section: {
      required: true,
      type: Object,
      default: () => {}
    },
    index: {
      required: true,
      type: Number,
      default: 0
    }
  },
  mixins: [nutritionProgramMixin],
  components: { ingestion },
  computed: {
    isActive () {
      return this.index === 0
    }
  },
  methods: {
    addNewItem () {
      this.section.nutrition_program_items_attributes.push(this.nutritionProgramItem())
    },
    removeNutritionProgramItem (index) {
      const item = this.section.nutrition_program_items_attributes[index]

      if (item.id) {
        this.$set(this.section.nutrition_program_items_attributes, index, {
          id: item.id,
          _destroy: true
        })
      } else {
        this.section.nutrition_program_items_attributes.splice(index, 1)
      }
    }
  }
}
</script>
