<template lang="pug">
  .pupil-nutrition-programs
    h4 Программы питания
      router-link.ml-2(:to="{ name: 'new_pupil_nutrition_program', params: { id: currentPupil.id } }")
        font-awesome-icon(icon="plus")

    p(v-if="currentPupil.nutrition_program")
      router-link(:to="{ name: 'pupil_nutrition_program_show', params: { program_id: currentPupil.nutrition_program.id } }") {{ currentPupil.nutrition_program.title }}
      span.badge.badge-secondary.ml-1 Актуальная

    button.btn.btn-outline-primary.mt-3(type="button"
      data-toggle="collapse"
      data-target="#nutrition-programs-list"
      v-if="currentPupil.nutrition_programs.length") Весь список программ питания

    ul.collapse.mt-2(id="nutrition-programs-list")
      nutrition-program(v-for="program in currentPupil.nutrition_programs"
        :key="program.id"
        :program="program")
</template>

<script>
import nutritionProgram from './nutrition_program'
import { mapGetters } from 'vuex'

export default {
  components: { nutritionProgram },
  computed: {
    ...mapGetters({
      currentPupil: 'coachPupils/current'
    })
  }
}
</script>
