






























import { mapGetters, mapActions } from 'vuex'
import { Component, Vue } from 'vue-property-decorator'
import material from './material.vue'
import newCategoryModal from '@/views/categories/new.vue'
import category from '@/views/categories/category.vue'
import videoViewer from '@/components/common/viewers/video_viewer.vue'
import InfiniteLoading from 'vue-infinite-loading'

@Component({
  components: { material, newCategoryModal, category, videoViewer, InfiniteLoading },
  computed: {
    ...mapGetters({
      allMaterials: 'materialsStore/all',
      categories: 'categoriesStore/all',
      selected: 'categoriesStore/selected'
    })
  },
  methods: {
    ...mapActions({
      getMaterials: 'materialsStore/index',
      getCategoriesIndex: 'categoriesStore/getIndex'
    })
  }
})

export default class MaterialsIndex extends Vue {
  getMaterials!: any
  getCategoriesIndex!: any

  mounted () {
    this.getCategoriesIndex()
  }

  loadMaterials ($state: any) {
    this.getMaterials().then((response: any) => {
      if (response.items.length) {
        $state.loaded()
      } else {
        $state.complete()
      }
    })
  }
}
