<template lang="pug">
  training-program(:current-user="currentUser" :trainingProgram="item")
</template>

<script>
import trainingProgram from '@/views/common/trainingProgram/show'
import { mapGetters } from 'vuex'

export default {
  components: { trainingProgram },
  computed: {
    ...mapGetters({
      currentUser: 'current_user',
      trainingProgramsArchive: 'trainingProgramsArchive'
    }),
    item () {
      return this.trainingProgramsArchive.find(tp => tp.id === parseInt(this.$route.params.program_id))
    }
  }
}
</script>
