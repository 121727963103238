<template lang="pug">
  form(v-if="currentUser" @submit.prevent="doUpdate")
    .form-group
      label(for="name") Имя
      input.form-control(type="text" v-model="editable.name")
    .form-group
      label(for="surname") Фамилия
      input.form-control(type="text" v-model="editable.surname")
    .form-group
      label(for="phone") Телефон
      input.form-control(type="text" v-model="editable.phone")
    .form-group
      label(for="avatar") Аватар
      input(type="file" @change="catchFile")
      img(:src="editable.avatar_url")

    button.btn.btn-primary(type="submit") Сохранить
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      editable: {}
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'current_user'
    })
  },
  mounted () {
    if (!this.currentUser) { return }

    this.editable = { ...this.currentUser }
  },
  watch: {
    currentUser () {
      this.editable = { ...this.currentUser }
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'updateUser',
      uploadAvatar: 'uploadAvatar'
    }),
    doUpdate () {
      const params = {
        user: this.editable
      }

      this.updateUser(params).then(() => {
        this.$notify({
          title: 'Обновление данных',
          type: 'success',
          text: 'Данные успешно обновлены'
        })
      })
    },
    catchFile (e) {
      this.editable.avatar = e.target.files[0]
      const params = { user: this.editable }

      this.uploadAvatar(params).then((response) => {
        this.editable = response
      })
    }
  }
}
</script>
