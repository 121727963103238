<template lang="pug">
  .nutrition-program-show-wrapper(v-if="currentPupil")
    nutritionProgram(:nutrition-program="nutritionProgram"
                     :can-edit="currentUser.role === 'coach'"
                     v-if="nutritionProgram")
</template>

<script>
import { mapGetters } from 'vuex'
import { currentPupilMixin } from '@/mixins/currentPupilMixin'
import nutritionProgram from '@/views/common/nutritionProgram/show'

export default {
  mixins: [currentPupilMixin],
  components: { nutritionProgram },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      getPupilNutritionProgram: 'coachPupils/getPupilNutritionProgram'
    }),
    nutritionProgram () {
      return this.getPupilNutritionProgram({ pupil_id: this.currentPupil.id, program_id: this.$route.params.program_id })
    }
  }
}
</script>
