import categoriesApi from '@/api/mainBackend/coach/categories'

const categoriesStore = {
  namespaced: true,
  state: {
    all: [],
    current: undefined,
    selected: []
  },

  getters: {
    all: state => state.all,
    current: state => state.current,
    selected: state => state.selected,
    isSelected: state => (item) => {
      const ids = state.selected.map(m => m.id)

      if (ids.indexOf(item.id) !== -1) return true

      return false
    }
  },

  actions: {
    async getIndex ({ commit, getters }, payload) {
      if (getters.all.length) { return }

      const response = await categoriesApi.index(payload)

      commit('commitGetIndex', response.data)
    },
    async getShow ({ commit, state, dispatch }, payload) {
      if (!state.all.length) {
        await dispatch('getIndex')
      }
      const response = await categoriesApi.show(payload)

      commit('commitCurrent', response.data)
    },
    setCategoryMaterial ({ commit }, payload) {
      commit('commitIncrementalMaterialsCount', payload.category_id)
    },
    async postCreate ({ commit }, payload) {
      const response = await categoriesApi.create(payload)

      commit('commitPostCreate', response.data)
    },
    async putUpdate ({ commit }, payload) {
      const response = await categoriesApi.update(payload)

      commit('commitPutUpdate', response.data)
    },
    pushSelected ({ commit }, payload) {
      commit('commitPushSelected', payload)
    }
  },

  mutations: {
    commitGetIndex (state, categories) {
      state.all = categories
    },
    commitPostCreate (state, category) {
      state.all.unshift(category)
    },
    commitPutUpdate (state, category) {
      let item = state.all.find(i => i.id === category.id)

      item = { ...category }

      return item
    },
    commitIncrementalMaterialsCount (state, categoryId) {
      const item = state.all.find(i => i.id === categoryId)

      item.materials_count += 1
    },
    commitCurrent (state, category) {
      state.current = category
    },
    commitPushSelected (state, material) {
      const ids = state.selected.map(m => m.id)
      const index = ids.indexOf(material.id)

      if (index === -1) {
        state.selected.push(material)
      } else {
        state.selected.splice(index, 1)
      }
    }
  }
}

export default categoriesStore
