import nutritionProgramApi from '@/api/mainBackend/coach/nutritionProgram'

const nutritionProgramsStore = {
  namespaced: true,

  state: [],
  getters: [],
  actions: {
    async create ({ commit, rootState }, payload) {
      const response = await nutritionProgramApi.create(payload)
      const pupilsState = rootState.coachPupils

      commit('unshiftNutritionProgram', {
        item: response.data.nutrition_program,
        pupil_id: payload.nutrition_program.pupil_id,
        pupilsState: pupilsState
      })

      return response.data
    },
    async update ({ commit, rootState }, payload) {
      const response = await nutritionProgramApi.update(payload)
      const pupilsState = rootState.coachPupils

      commit('setNutritionProgram', {
        item: response.data.nutrition_program,
        pupilsState: pupilsState
      })

      return response.data
    },
    async index ({ commit, rootState }, payload) {
      const response = await nutritionProgramApi.index(payload)
      const pupilsState = rootState.coachPupils

      commit('setNutritionPrograms', {
        items: response.data,
        userId: payload.user_id,
        pupilsState: pupilsState
      })
    },
    async getActual ({ commit, rootState }, payload) {
      const response = await nutritionProgramApi.getActual(payload)
      const pupilsState = rootState.coachPupils

      commit('setActualNutritionProgram', {
        item: response.data,
        userId: payload.user_id,
        pupilsState: pupilsState
      })
    }
  },
  mutations: {
    setNutritionProgram (state, { item, pupilsState }) {
      if (pupilsState.current.nutrition_program.id === item.id) {
        pupilsState.current.nutrition_program = item
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let nutritionProgram = pupilsState.current.nutrition_programs.find(tp => tp.id === item.id)
      nutritionProgram = item
    },
    unshiftNutritionProgram (state, { item, pupilsState }) {
      const pupil = pupilsState.current

      if (pupil === undefined) { return }

      pupil.nutrition_programs.unshift(pupil.nutrition_program)
      pupil.nutrition_program = { ...item }
    },
    setNutritionPrograms (state, { items, userId, pupilsState }) {
      let user = pupilsState.all.find(u => u.id === userId)

      if (!user) { user = pupilsState.current }
      if (user.nutrition_programs.length) { return }

      user.nutrition_programs = items
    },
    setActualNutritionProgram (state, { item, userId, pupilsState }) {
      let user = pupilsState.all.find(u => u.id === userId)

      if (!user) { user = pupilsState.current }
      if (user.nutrition_program) { return }

      user.nutrition_program = item
    }
  }
}

export default nutritionProgramsStore
