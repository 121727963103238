<template lang="pug">
  .category(v-if="category")
    .row.row-cols-1.row-cols-md-2.mt-3.row-cols-lg-4
      material(v-for="item in category.materials" :item="item" :key="item.id")
</template>

<script>
import material from '@/views/coach/materials/material'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { material },
  mounted () {
    this.getShow({ category_id: this.id })
  },
  computed: {
    ...mapGetters({
      category: 'categoriesStore/current'
    }),
    id () {
      return JSON.parse(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions({
      getShow: 'categoriesStore/getShow'
    })
  }
}
</script>
