<template lang="pug">
  .section.mt-3.mb-3(v-if="isActive")
    draggable(v-model="section_params.training_program_items_attributes"
              draggable=".section__item"
              delay="500"
              delayOnTouchOnly="true"
              @end="onEnd")
      .section__item(v-for="(item, index) in section_params.training_program_items_attributes" v-if="!item._destroy")
        .section__attributes
          .col-12.d-flex.justify-content-end
            a(href="#" @click.prevent="emitRemoveTrainingProgramItem(index)") Удалить упражнение
              font-awesome-icon.ml-2(icon="trash")

          label(:for="`title-${index}-${sectionIndex}`") Упражнение
          input.section__item_title.form-control.col-12.mr-2.mb-2(v-model="item.title"
            placeholder="Упражнение"
            type="text"
            :id="`title-${index}-${sectionIndex}`")

          label(:for="`number_of_approaches-${index}-${sectionIndex}`") Количество подходов (или время упражнения, например, кардио)
          input.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="item.number_of_approaches"
            placeholder="Количество подходов"
            type="text"
            :id="`number_of_approaches-${index}-${sectionIndex}`")

          label(:for="`number_of_repeats-${index}-${sectionIndex}`") Количество повторений
          input.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="item.number_of_repeats"
            placeholder="Количество повторений"
            type="text"
            :id="`number_of_repeats-${index}-${sectionIndex}`")

          label(:for="`weight-${index}-${sectionIndex}`") Вес
          input.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="item.weight"
            placeholder="Вес"
            type="text"
            :id="`weight-${index}-${sectionIndex}`")

          label(:for="`rest_time-${index}-${sectionIndex}`") Время отдыха, минуты
          input.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="item.rest_time"
            placeholder="Отдых, минуты"
            type="text"
            :id="`rest_time-${index}-${sectionIndex}`")

          template
            div(v-for="(material, idx) in item.training_program_item_materials_attributes"
                v-if="!material._destroy")
              label(:for="`video-${index}-${sectionIndex}`") Видео {{ idx + 1 }} для упражнения (загрузить новое)
                a.ml-1(href="#"
                  @click.prevent="removeMaterial(item, idx)"
                  v-if="visibleMaterials(item) && visibleMaterials(item).length > 1") Удалить видео
                  font-awesome-icon.ml-2(icon="trash")
              file-upload(:item="material" :index="`${index}-${idx}`" :sectionIndex="`${sectionIndex} - ${idx}`")

              template(v-if="!item.file && !item.file_name")
                label(:for="`material_id-${index}-${sectionIndex}-${idx}`") Видео упражнения (Выбрать из материалов. Не обязательно, если загружен файл)
                select.col-12.custom-select(v-model="material.material_id" :id="`material_id-${index}-${sectionIndex}-${idx}`")
                  option(selected disabled value) Выбрать видео из библиотеки
                  optgroup(v-for="obj in groupedMaterials" :label="obj[0].category_title || 'Без категории'")
                    option(v-for='video in obj' :value="video.id") {{ video.title }}

            .row
              .col-12
                a(href="#"
                  @click.prevent="addMaterial(item)"
                  v-if="visibleMaterials(item) && visibleMaterials(item).length < 3") Добавить видео

          label(:for="`comment-${index}-${sectionIndex}`") Комментарий к упражнению
          textarea.section__item_title.form-control.col-12.mr-2.mr-2.mb-2(v-model="item.comment"
            placeholder="Комментарий к упражнению"
            rows="3" cols="15"
            type="text"
            :id="`comment-${index}-${sectionIndex}`")

        hr
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import fileUpload from '@/components/fileUpload'
import draggable from 'vuedraggable'

export default {
  props: {
    section: {
      required: true,
      type: Object,
      default: () => {}
    },
    sectionIndex: {
      required: true,
      type: Number,
      default: 0
    },
    isActive: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  components: { fileUpload, draggable },
  data () {
    return {
      section_params: {
        title: `День ${this.sectionIndex + 1}`,
        position: this.section.position,
        training_program_items_attributes: [],
        comment: this.section.comment
      }
    }
  },
  computed: {
    ...mapGetters({
      materials: 'materialsStore/all'
    }),
    groupedMaterials () {
      return this.$_.groupBy(this.materials, 'category_title')
    }
  },
  mounted () {
    if (!this.materials.length) {
      this.getMaterials().then(() => {
        this.section_params = Object.assign({}, this.section)
      })
    } else {
      this.section_params = Object.assign({}, this.section)
    }
  },
  methods: {
    ...mapActions({
      getMaterials: 'materialsStore/index'
    }),
    emitRemoveTrainingProgramItem (index) {
      this.$emit('removeTrainingProgramItem', { sectionIndex: this.sectionIndex, index: index })
    },
    addMaterial (item) {
      item.training_program_item_materials_attributes.push(
        {
          material_id: '',
          position: this.visibleMaterials(item).length
        }
      )
    },
    removeMaterial (item, idx) {
      const i = item.training_program_item_materials_attributes[idx]
      this.$set(item.training_program_item_materials_attributes, idx, {
        id: i.id,
        _destroy: true
      })
    },
    visibleMaterials (item) {
      return item.training_program_item_materials_attributes.filter((material) => {
        if (!material._destroy) { return material }
      })
    },
    onEnd (evt) {
      const newIndex = evt.newIndex
      const oldIndex = evt.oldIndex

      const oldItem = this.section_params.training_program_items_attributes[newIndex]
      const newItem = this.section_params.training_program_items_attributes[oldIndex]

      oldItem.position = newIndex
      newItem.position = oldIndex
    }
  }
}
</script>
