<template lang="pug">
  training-program(:training-program="actualTrainingProgram" v-if="actualTrainingProgram")
</template>

<script>
import trainingProgram from '@/views/common/trainingProgram/show'
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted () {
    if (!this.actualTrainingProgram) {
      this.getActualTrainingProgram()
    }
  },
  components: { trainingProgram },
  computed: {
    ...mapGetters({
      currentUser: 'current_user',
      actualTrainingProgram: 'actualTrainingProgram'
    })
  },
  methods: {
    ...mapActions({
      getActualTrainingProgram: 'getActualTrainingProgram'
    })
  }
}
</script>
