import Vue from '@/lib/vue.js'
import VueRouter from 'vue-router'

import trainingProgramForm from '@/views/coach/training_programs/form.vue'
import sharedTrainingProgramForm from '@/views/coach/sharedTrainingPrograms/form.vue'
import trainingProgramShow from '@/views/coach/training_programs/show.vue'
import pupilTrainingProgramShow from '@/views/coach/pupils/trainingPrograms/show.vue'
import PupilNutritionProgramForm from '@/views/coach/nutrition_programs/form.vue'
import PupilNutritionProgramShow from '@/views/coach/nutrition_programs/show.vue'
import pupilTrainingPrograms from '@/views/coach/pupils/trainingPrograms.vue'
import pupilNutritionPrograms from '@/views/coach/pupils/nutritionPrograms.vue'

import MaterialsIndex from '@/views/coach/materials/index.vue'
import MaterialForm from '@/views/coach/materials/form.vue'

import categoryShow from '@/views/categories/show.vue'

import PupilIndex from '@/views/pupil/index.vue'
import PupilTrainingProgramsArchiveIndex from '@/views/pupil/training_programs/archive.vue'
import PupilTrainingProgramsShow from '@/views/pupil/training_programs/show.vue'
import pupilTrainingProgram from '@/views/pupil/training_programs/trainingProgram.vue'
import pupilNutritionProgram from '@/views/pupil/nutrition_programs/nutritionProgram.vue'

import SettingsDashboard from '@/views/common/settings/dashboard.vue'
import SettingsProfile from '@/views/common/settings/profile.vue'
import SettingsMain from '@/views/common/settings/main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'root_page',
    component: () => import(/* webpackChunkName: 'root' */ '@/views/root/index.vue'),

    children: [
      {
        path: '/users/sign_in',
        name: 'sign_in',
        component: () => import(/* webpackChunkName: "users-sign-in" */ '@/views/users/login/form.vue')
      },

      {
        path: '/users/registration',
        name: 'registration',
        component: () => import(/* webpackChunkName: "users-registration" */ '@/views/users/registration/simpleForm.vue')
      },

      {
        path: '/users/send_reset_password_instructions',
        name: 'sendResetPasswordInstructionsForm',
        component: () => import(/* webpackChunkName: "users-reset" */ '@/views/users/reset/sendResetPasswordInstructionsForm.vue')
      },

      {
        path: '/users/reset_password',
        name: 'resetPasswordForm',
        component: () => import(/* webpackChunkName: "users-reset" */ '@/views/users/reset/resetPasswordForm.vue')
      },

      {
        path: '/users/accept_invitation',
        name: 'accept_invitation',
        component: () => import(/* webpackChunkName: "users-accept-invitation" */ '@/views/users/invitation/accept.vue')
      },
      {
        path: '/coach',
        component: () => import(/* webpackChunkName: "coach-index" */ '@/views/coach/index.vue'),
        name: 'coach_index',
        meta: { breadcrumb: [{ title: 'Мой кабинет', routeName: 'coach_index' }] },
        children: [
          {
            path: '/coach/pupils',
            component: () => import(/* webpackChunkName: "coach-pupils" */ '@/views/coach/pupils/index.vue'),
            name: 'pupils_index'
          },
          {
            path: '/coach/pupils/new',
            component: () => import(/* webpackChunkName: "coach-pupils" */ '@/views/coach/pupils/new.vue'),
            name: 'pupil_new',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список учеников', routeName: 'pupils_index' },
                { title: 'Добавление нового ученика', routeName: 'pupil_new' }
              ]
            }
          },
          {
            path: '/coach/pupils/:id',
            component: () => import(/* webpackChunkName: "coach-pupils" */ '@/views/coach/pupils/show.vue'),
            name: 'pupil_show',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список учеников', routeName: 'pupils_index' },
                { title: (obj: any) => obj.full_name, routeName: 'pupil_show' }
              ]
            },
            children: [
              { path: '/coach/pupils/:id/training_programs', component: pupilTrainingPrograms, name: 'pupil_training_programs' },
              { path: '/coach/pupils/:id/nutrition_programs', component: pupilNutritionPrograms, name: 'pupil_nutrition_programs' },
              { path: '/coach/pupils/:id/training_programs/:program_id', component: pupilTrainingProgramShow, name: 'pupil_training_program_show' },
              { path: '/coach/pupils/:id/training_programs/:program_id/edit', component: trainingProgramForm, name: 'edit_pupil_training_program' }
            ]
          },
          {
            path: '/coach/pupils/:id/training_programs/new',
            component: trainingProgramForm,
            name: 'new_pupil_training_program',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список учеников', routeName: 'pupils_index' },
                { title: (obj: any) => obj.full_name, routeName: 'pupil_show' },
                { title: 'Добавление новой программы тренировок', routeName: 'new_pupil_training_program' }
              ]
            }
          },
          {
            path: '/coach/pupils/:id/nutrition_programs/new',
            component: PupilNutritionProgramForm,
            name: 'new_pupil_nutrition_program',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список учеников', routeName: 'pupils_index' },
                { title: (obj: any) => obj.full_name, routeName: 'pupil_show' },
                { title: 'Добавление новой программы питания', routeName: 'new_pupil_nutrition_program' }
              ]
            }
          },
          {
            path: '/coach/pupils/:id/nutrition_programs/:program_id',
            component: PupilNutritionProgramShow,
            name: 'pupil_nutrition_program_show',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список учеников', routeName: 'pupils_index' },
                { title: (obj: any) => obj.full_name, routeName: 'pupil_show' },
                { title: (obj: any) => obj.title, routeName: 'pupil_nutrition_program_show' }
              ]
            }
          },
          {
            path: '/coach/pupils/:id/nutrition_programs/:program_id/edit',
            component: PupilNutritionProgramForm,
            name: 'edit_pupil_nutrition_program',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список учеников', routeName: 'pupils_index' },
                { title: (obj: any) => obj.full_name, routeName: 'pupil_show' },
                { title: (obj: any) => obj.title, routeName: 'pupil_nutrition_program_show' },
                { title: 'Редактирование', routeName: 'edit_pupil_nutrition_program' }
              ]
            }
          },
          {
            path: '/coach/training_programs',
            component: () => import(/* webpackChunkName: "coach-training-programs" */ '@/views/coach/training_programs/index.vue'),
            name: 'shared_training_programs_index'
          },
          {
            path: '/coach/training_programs/new',
            component: sharedTrainingProgramForm,
            name: 'new_shared_training_program'
          },
          {
            path: '/coach/training_programs/:id',
            component: trainingProgramShow,
            name: 'shared_training_programs_show'
          },
          {
            path: '/coach/shared_training_programs/:id/edit',
            component: sharedTrainingProgramForm,
            name: 'edit_shared_training_program'
          },
          {
            path: '/coach/materials',
            component: MaterialsIndex,
            name: 'materials_index',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список материалов', routeName: 'materials_index' }
              ]
            }
          },
          {
            path: '/coach/materials/new',
            component: MaterialForm,
            name: 'new_material',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список материалов', routeName: 'materials_index' },
                { title: 'Добавление материала', routeName: 'new_material' }
              ]
            }
          },
          {
            path: '/coach/materials/:id',
            component: MaterialForm,
            name: 'edit_material',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Список материалов', routeName: 'materials_index' },
                { title: (obj: any) => obj.title, routeName: 'edit_material' }
              ]
            }
          },
          {
            path: '/coach/categories/:id',
            component: categoryShow,
            name: 'category_show',
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'coach_index' },
                { title: 'Библиотека видео', routeName: 'materials_index' },
                { title: 'Просмотр категории', routeName: 'category_show' }
              ]
            }
          }
        ]
      },
      {
        path: '/pupil',
        name: 'pupil_index',
        component: PupilIndex,
        meta: {
          breadcrumb: [
            { title: 'Мой кабинет', routeName: 'pupil_index' }
          ]
        },
        children: [
          {
            path: '/pupil/training_program',
            name: 'pupil_training_program',
            component: pupilTrainingProgram,
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'pupil_index' },
                { title: 'Архивные программы тренировок', routeName: 'training_programs_index' }
              ]
            }
          },
          {
            path: '/pupil/nutrition_program',
            name: 'pupil_nutrition_program',
            component: pupilNutritionProgram,
            meta: {
              breadcrumb: [
                { title: 'Мой кабинет', routeName: 'pupil_index' },
                { title: 'Архивные программы тренировок', routeName: 'training_programs_index' }
              ]
            }
          }
        ]
      },
      {
        path: '/pupil/training_programs',
        name: 'training_programs_archive_index',
        component: PupilTrainingProgramsArchiveIndex,
        meta: {
          breadcrumb: [
            { title: 'Мой кабинет', routeName: 'pupil_index' },
            { title: 'Архивные программы тренировок', routeName: 'training_programs_index' }
          ]
        }
      },
      {
        path: '/pupil/training_programs/:program_id',
        name: 'training_programs_show',
        component: PupilTrainingProgramsShow,
        meta: {
          breadcrumb: [
            { title: 'Мой кабинет', routeName: 'pupil_index' },
            { title: 'Архивные программы тренировок', routeName: 'training_programs_index' },
            { title: (obj: any) => obj.title, routeName: 'training_programs_show' }
          ]
        }
      },
      {
        path: '/settings',
        name: 'settings_dashboard',
        component: SettingsDashboard,
        meta: {
          breadcrumb: [
            { title: 'Настройки', routeName: 'settings_dashboard' }
          ]
        },
        children: [
          {
            path: 'profile',
            name: 'settings_profile',
            component: SettingsProfile,
            meta: {
              breadcrumb: [
                { title: 'Настройки', routeName: 'settings_dashboard' },
                { title: 'Анкета', routeName: 'settings_profile' }
              ]
            }
          },
          {
            path: 'main',
            name: 'settings_main',
            component: SettingsMain,
            meta: {
              breadcrumb: [
                { title: 'Настройки', routeName: 'settings_dashboard' },
                { title: 'Основные', routeName: 'settings_main' }
              ]
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
